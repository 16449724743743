import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline } from "@mdi/js"; 
import { useState, useEffect } from "react";

const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    parent_id: '',
    photo: '',
    photo2: '',
    status: '',
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');

  const [parent_id, setParentId] = useState('');
  //const [parent_id, setParentId] = useState(formData.parent_id || '');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);

  useEffect(() => {
    fetch('https://funny.webixtechnologies.co.in/getCategoryName')
    //fetch('http://localhost:3000/getCategoryName')
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          const options = data.categories.map((category) => ({
            label: category.name,
            value: category.id,
          }));
          setCategoryOptions(options);
        } else {
          console.error('Failed to fetch category names:', data.msg);
        }
      })
      .catch((error) => {
        console.error('Error fetching category names:', error);
      });
  }, []);

  const handleSelectChange = (event) => {
    setParentId(event.target.value);
  };

  useEffect(() => {
    fetch(`https://funny.webixtechnologies.co.in/getCategoryById/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setFormData(data);
        setStatus(data.status);
        setParentId(data.parent_id);
        setPhotoFile(data.photo);
        setLighttechPhotoFile(data.photo2);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "status") {
      setStatus(value);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setParentId(event.target.value);
    }
  };

  const [photo, setPhotoFile] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewVisible, setPreviewVisible] = useState(true);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPhotoFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    setPreviewVisible(!!file);
  };

  const [photo2, setLighttechPhotoFile] = useState('');
  const [lighttechPreviewUrl, setLighttechPreviewUrl] = useState('');
  const [lighttechPreviewVisible, setLighttechPreviewVisible] = useState(true);

  const handleLighttechFileChange = (event) => {
    const file1 = event.target.files[0];
    setLighttechPhotoFile(file1);
    setLighttechPreviewUrl(URL.createObjectURL(file1));
    setLighttechPreviewVisible(!!file1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('name', name);
      if (parent_id) {
        formData.append('parent_id', parent_id);
      }
      if (photo) {
        formData.append('photo', photo);
      }
      if (photo2) {
        formData.append('photo2', photo2);
      }
      formData.append('status', status);
      //console.log(formData.get('parent_id'));
      console.log(formData.get('photo2'));
      
      fetch(`https://funny.webixtechnologies.co.in/editCategory/${id}`, {
        method: 'PUT',
        body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('Data updated:', data);
        alert('Data updated:', data);
        navigate("/category");
      })
      .catch((error) => {
        console.error('Error updating data:', error);
      });
    }
  };
  const { name } = formData;
  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = 'First Name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        {/*begin::Main*/}
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {/*begin::Aside*/}
            <Sidebar />
            {/*end::Aside*/}
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              {/*begin::Header*/}
              <Header />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Ecit Category
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Edit Category
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/category" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> Category
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleChange}
                                      />
                                      {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Parent Category
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="parent_id"
                                        value={formData.parent_id}
                                        onChange={handleChange}
                                        >
                                        <option value="">Select Parent Category</option>
                                        <option value="0">0</option>
                                        {categoryOptions.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                      <div className="mb-10">
                                      <label className="form-label">Webix Photo</label>
                                      <div></div>
                                        <input type="file" accept="image/*" onChange={handleFileChange}  id="photo" name="photo" /> 
                                        {/* <label htmlFor="photo">
                                            <a className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm"  color="primary">Attach File</a>
                                        </label> */}
                                      </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">Lighttech Photo</label>
                                      <div></div>
                                      <input type="file" accept="image/*" onChange={handleLighttechFileChange}  id="photo2" name="photo2" />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Status
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="active"
                                          checked={status === 'active'}
                                          onChange={handleChange}
                                        />
                                        <label className="form-check-label me-2">
                                          Inactive
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="inactive"
                                          checked={status === 'inactive'}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                    {photo && (
                                       <img src={"https://funny.webixtechnologies.co.in/uploads/image/" + photo} alt="" style={{ maxWidth: '50%', }} />
                                    )}
                                    {!photo && (
                                      <div style={{ display: 'none' }}></div>
                                    )}
                                    {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: '50%' }} />}
                                    {/* {photo && <img src={photo} alt="Preview" style={{ maxWidth: '100%' }} />} */}
                                    </div>
                                    
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      {photo2 && (
                                        <img src={"https://funny.webixtechnologies.co.in/uploads/lighttech/" + photo2} alt="" style={{ maxWidth: '50%', }} />
                                      )}
                                      {!photo2 && (
                                        <div style={{ display: 'none' }}></div>
                                      )}
                                      {lighttechPreviewUrl && <img src={lighttechPreviewUrl} alt="Preview" style={{ maxWidth: '50%' }} />}
                                    </div> 
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div className="col-lg-2">
                                    <div className="mb-10">
                                      {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%' }} />}
                                    </div>
                                  </div>
                                </div> */}
                                <div className="card-footer d-flex justify-content-end py-6 px-0">
                                  <button
                                    type="submit"
                                    className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                  >
                                    Save
                                  </button>
                                  <Link to="/category"
                                    type="reset"
                                    className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm me-2"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Content*/}
              {/*begin::Footer*/}
              <Footer />
              {/*end::Footer*/}
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
