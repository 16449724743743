import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline } from "@mdi/js"; 
import React, { useEffect, useState} from "react";

const AddSound = () => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('active');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  
  const [category_id, setCategoryId] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    fetch('https://funny.webixtechnologies.co.in/getCategoryName')
    //fetch('https://prank.loopbots.in/getCategory')
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          const options = data.categories.map((category) => ({
            label: category.name,
            value: category.id,
          }));
          setCategoryOptions(options);
        } else {
          console.error('Failed to fetch category names:', data.msg);
        }
      })
      .catch((error) => {
        console.error('Error fetching category names:', error);
      });
  }, []);

  const handleSelectChange = (event) => {
    setCategoryId(event.target.value);
  };

  const [photo, setPhotoFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPhotoFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const [photo2, setLighttechPhotoFile] = useState(null);
  const [lighttechPreviewUrl, setLighttechPreviewUrl] = useState('');

  const handleLighttechFileChange = (event) => {
    const file2 = event.target.files[0];
    setLighttechPhotoFile(file2);
    setLighttechPreviewUrl(URL.createObjectURL(file2));
  };

  const [sound, setSoundFile] = useState('');
  const [soundPreviewUrl, setSoundPreviewUrl] = useState('');

  const handleFileChangeSound = (event) => {
    const file1 = event.target.files[0];
    setSoundFile(file1);
    setSoundPreviewUrl(URL.createObjectURL(file1));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append('name', name);
      //formData.append('category_id', category_id || null);
      formData.append('status', status);
      formData.append('photo', photo);
      formData.append('photo2', photo2);
      formData.append('sound', sound);
      // const selectedCategory = categoryOptions.find((option) => option.value === category_id);
      // if (selectedCategory) {
      //   formData.append('category_id', selectedCategory.label); // Store the category name in the form data
      // }
      if (category_id) {
        formData.append('category_id', category_id);
      }
      console.log(formData.get('category_id'));
      // //console.log(formData.category_id)

      fetch('https://funny.webixtechnologies.co.in/addSound', {
      //fetch('http://localhost:3000/addSound', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          alert('Form submitted successfully!', data);
          navigate("/sound");
        })
        .catch((error) => {
          console.error('Form submission failed:', error);
        });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!category_id) {
      errors.category_id = 'Category is required';
    }
    if (!sound) {
      errors.sound = 'Audio is required';
    } else {
      const allowedExtensions = ['.mp3', '.wav'];
      const fileExtension = sound.name.substring(sound.name.lastIndexOf('.')).toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        errors.sound = 'Invalid file format. Please upload an MP3 or WAV file.';
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        {/*begin::Main*/}
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {/*begin::Aside*/}
            <Sidebar />
            {/*end::Aside*/}
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              {/*begin::Header*/}
              <Header />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Add New Sound
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Add New Sound
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/sound" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> Sound List
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                      {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Category
                                      </label>
                                      <select 
                                        className="form-select"
                                        isSearchable={true}
                                        name="category_id"
                                        value={category_id}
                                        onChange={handleSelectChange}
                                        >
                                        <option value="">Select Category</option>
                                        {categoryOptions.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.category_id && <span style={{ color: "red" }}>{errors.category_id}</span>}
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Category Name
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="category_id"
                                        value={category_id}
                                        onChange={handleSelectChange}
                                        >
                                        <option value="">Select Category</option>
                                        {categoryValues.map((opts, i) => (
                                          <option key={i}>{opts.name}</option>
                                        ))}
                                      </select>
                                      {errors.category_id && <span style={{ color: "red" }}>{errors.category_id}</span>}
                                    </div>
                                  </div> */}
                                </div>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">Webix Photo</label>
                                      <div></div>
                                        <input type="file" accept="image/*" onChange={handleFileChange}  id="photo" name="photo" /> 
                                        {/* <label htmlFor="photo">
                                            <a className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm"  color="primary">Attach File</a>
                                        </label> */}
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">Lighttech Photo</label>
                                      <div></div>
                                        <input type="file" accept="image/*" onChange={handleLighttechFileChange}  id="photo2" name="photo2" />
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                      <div className="mb-10">
                                      <label className="required form-label">Audio</label>
                                      <div></div>
                                          <input type="file" accept=".Mp3/" onChange={handleFileChangeSound}  id="sound" name="sound" /> 
                                          {/* <label htmlFor="sound">
                                              <a className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm"  color="primary">Attach File</a>
                                          </label> */}
                                      </div>
                                      {errors.sound && <span style={{ color: "red" }}>{errors.sound}</span>}
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Status
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="active"
                                          checked={status === 'active'}
                                          onChange={(e) => setStatus(e.target.value)}
                                        />
                                        <label className="form-check-label me-2">
                                          Inactive
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="inactive"
                                          checked={status === 'inactive'}
                                          onChange={(e) => setStatus(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: '50%' }} />}
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      {lighttechPreviewUrl && <img src={lighttechPreviewUrl} alt="Preview" style={{ maxWidth: '50%' }} />}
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      {sound && ( <audio src={soundPreviewUrl} controls></audio> )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end py-6 px-0">
                                  <button
                                    type="submit"
                                    className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                  >
                                    Save
                                  </button>
                                  <Link to="/sound"
                                    type="reset"
                                    className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Content*/}
              {/*begin::Footer*/}
              <Footer />
              {/*end::Footer*/}
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default AddSound;
