import "./App.css";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
/*import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/profile/ChangePassword";
import Users from "./pages/user/Users";
import AddUsers from "./pages/user/AddUsers";
import EditUsers from "./pages/user/EditUsers";*/
import Category from "./pages/category/Category";
import AddCategory from "./pages/category/AddCategory";
import EditCategory from "./pages/category/EditCategory";
import Sound from "./pages/sound/Sound";
import AddSound from "./pages/sound/AddSound";
import EditSound from "./pages/sound/EditSound";

function App() {
  return (
      <Routes>
        {/* <Route index element={<Category/>}/> */}
        <Route index element={<Login />} />
        <Route path="category" element={<Category/>}/>
        <Route path='/addcategory' element={<AddCategory />} />
        <Route path='/editcategory/:id' element={<EditCategory />} />
        <Route path="sound" element={<Sound/>}/>
        <Route path='/addsound' element={<AddSound />} />
        <Route path='/editsound/:id' element={<EditSound />} />
        
        {/* <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="change_password" element={<ChangePassword />} />
        <Route path="users" element={<Users/>}/>
        <Route path="adduser" element={<AddUsers/>}/>
        <Route path='/edituser/:id' element={<EditUsers />} /> 
        <Route index path="category" element={<Category/>}/>*/}
      </Routes>
  );
}
export default App;
