import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Icon from "@mdi/react";
import {mdiPlusCircleOutline, mdiDelete, mdiPencil} from "@mdi/js";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState} from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Modal} from "react-bootstrap";

const Category = () => {
  const url = "https://funny.webixtechnologies.co.in/getAllAdminCategoryData";
  //const url = "http://localhost:3000/getAllData";
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios.get(url)
      .then(response => {
        console.log(setData(response.data.data));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://funny.webixtechnologies.co.in/deleteCategory/${id}`, {
          method: 'DELETE'
        })
        .then(response => {
          if (response.ok) {
            Swal.fire({
              title: 'Deleted!',
              text: 'Category deleted successfully.',
              icon: 'success',
              onClose: () => {
                navigate("/category");
              }
            });
          } else {
            throw new Error('Failed to delete category');
          }
        })
        .catch(error => {
          console.error(error);
        });
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()) ||
    item._id.toLowerCase().includes(search.toLowerCase())
  );
  const currentRecords = filteredData
  .slice(indexOfFirstRecord, indexOfLastRecord)
  .map((item) => {
    return { ...item, sort_order: item.sort_order };
  });


  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({ sort_order: '', });

  const handleShowModel = (id) => {
    handleShow();
    fetch(`https://funny.webixtechnologies.co.in/getCategoryById/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setFormData(data);
        setSortOrder(data.sort_order);
        setId(id);
      })
  }
  
  const handleChange = (e) => {
    setFormData({ ...formData, sort_order: e.target.value });
  };

  const [error, setError] = useState(false);
  const [sort_order, setSortOrder] = useState("")
  const [id, setId] = useState('');

  const handleUpdateSortOrder = (event) => {
    event.preventDefault();
    if (formData.sort_order.trim() === '') {
      setError(true);
    } else {
      setError(false);
      const updatedData = { sort_order: formData.sort_order || "0" };
      axios.put(`https://funny.webixtechnologies.co.in/updateSortOrder/${id}`, updatedData)
      .then((response) => {
        console.log("Data updated:", response.data);
        handleClose();
        const updatedDataArray = currentRecords.map((item) => {
          if (item._id === id) {
            return { ...item, sort_order: formData.sort_order };
          }
          return item;
        });
        setData(updatedDataArray);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
    }
  };
  

  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        {/*begin::Main*/}
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {/*begin::Aside*/}
            <Sidebar />
            {/*end::Aside*/}
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              {/*begin::Header*/}
              <Header />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Category
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div
                    id="kt_content_container"
                    className="container-xxl"
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Category
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link
                              to="/addcategory"
                              className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"
                            >
                              <Icon path={mdiPlusCircleOutline} size={1} /> Add Category
                            </Link>
                          </div>
                        </div>
                        <div className="card-header border-0 pt-6">
                          <div className="card-toolbar"></div>
                          <div className="card-title mx-0">
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-200px ps-15"
                                placeholder="Search..."
                                value={search}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <div className="my-1">
                              <table
                                id="kt_datatable_example_4"
                                className="table table-striped gy-5 gs-7 border rounded"
                              >
                                <thead>
                                  <tr className="fw-bolder fs-6 text-gray-800 px-7">
                                  <th className="min-w-50px pe-2">ID</th>
                                    <th className="min-w-100px">Category Name</th>
                                    <th className="min-w-100px">Parent ID</th>
                                    <th className="min-w-100px">Sort Order</th>
                                    <th className="min-w-100px">Status</th>
                                    <th className="min-w-100px">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {/* {data.slice(0, recordsPerPage).map((item, index) => ( */}
                                {currentRecords.length === 0 ? (
                                  <tr style={{ textAlign: "center" }}><td colSpan={5}>No records found data</td></tr>
                                ) : (
                                  currentRecords.map((item, index) => (
                                    //{data.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item._id}</td>
                                      <td>{item.name}</td>
                                      <td>{item.parent_id}</td>
                                      <td><span
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        onClick={() => {
                                          handleShowModel(item._id);
                                        }}
                                        >{item.sort_order}</span>
                                      </td>
                                      <td>
                                        {item.status === "inactive" ? (
                                          <span className="badge badge-light-danger">
                                            Inactive
                                          </span>
                                        ) : (
                                          <span className="badge badge-light-success">
                                            Active
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <Link to={`/editcategory/${item._id}`}
                                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                          <Icon path={mdiPencil} size={1} />
                                        </Link>
                                        <button 
                                          className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                          onClick={() => handleDelete(item._id)}
                                          >
                                          <Icon path={mdiDelete} size={1} />
                                        </button>
                                      </td>
                                    </tr>
                                  )))} 
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="card-header border-0">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-start mb-4">
                              <div className="dataTables_length d-flex align-items-center">
                                <label className="me-3">
                                  <select className="form-select form-select-sm form-select-solid"
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(Number(e.target.value))
                                    }
                                  >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                </label>
                                <div className="fs-6 fw-bold text-gray-700">
                                  {/* Showing {indexOfFirstRecord + 1} to{" "} */}
                                  {/* {Math.min(indexOfLastRecord, data.length)} of {data.length} entries */}
                                  {/* {Math.min(indexOfLastRecord,filteredData.length)}{" "}of {filteredData.length} entries */}
                                  Showing {indexOfFirstRecord + 1} to {Math.min(indexOfLastRecord, filteredData.length)} of {filteredData.length} entries
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-end">
                              <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={recordsPerPage}
                                totalItemsCount={filteredData.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination"
                                activeClass="active"
                                disabledClass="disabled"
                                prevPageText="Previous"
                                nextPageText="Next"
                                hideFirstLastPages={true}
                                hideDisabled={true}
                                hideNavigation={false}
                                hideNavigationIcons={false}
                                hideFirstPageText={false}
                                hideLastPageText={false}
                              />
                            </div>
                          </div>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Update Sort Order</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="card">
                              <form onSubmit={handleUpdateSortOrder}>
                                <div className="card-body">
                                  <div className="card card-custom shadow mb-5">
                                    <div className="card-body">
                                        <div className="py-10">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="mb-10">
                                                <label
                                                  htmlFor="sort_order"
                                                  className="required form-label"
                                                >
                                                  Sort Order
                                                </label>
                                                <input
                                                  type="text"
                                                  //className="form-control"
                                                  className={`form-control ${error ? 'is-invalid' : ''}`}
                                                  placeholder="Sort Order"
                                                  name="sort_order"
                                                  id="sort_order"
                                                  value={formData.sort_order || ''}
                                                  onChange={handleChange}
                                                />
                                                {error && (<div className="invalid-feedback">Sort Order is required.</div>)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="d-flex justify-content-end border-top px-9 pt-3">
                                      <button
                                        className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm me-2"
                                        type="submit"
                                        id="Update"
                                      >
                                        <span>
                                          Update
                                        </span>
                                      </button>
                                      <button className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger btn-sm   cancel"
                                        onClick={handleClose}
                                      >
                                          Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Content*/}
              {/*begin::Footer*/}
              <Footer />
              {/*end::Footer*/}
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default Category;
